<template>
  <div v-if="showListView" class="list-view">
    <div class="list-view-complete" v-if="isFullList">
      <Topmenu :showList="false" :showList2="false"></Topmenu>
      <div class="filter">
        <div class="closeFullList" @click="closeFullList" :arial-label="$t('back')">
          <div class="tag" :id="'tagBack'" :ref="'tagBack'" :load="loadSVG('tagBack', 'b_back.svg', colors.color_principal)"></div>
          <span>{{ $t("back") }}</span>
        </div>
        <!--<div class="filterText" :aria-label="$t('filter')">{{ $t("filter") }}</div>-->
        <div class="filterCont">
          <div class="filterOpt" @click="filterList('custom')" v-if="isCustom" id='filtercustom'
            :aria-label="$t('yourguide.title')">{{ $t("yourguide.title") }}</div>
          <div class="filterOpt" v-for="(visit) in listVisits" :key="visit.visita_id"
            @click="filterList(visit.visita_id)" :id="'filter' + visit.visita_id" :aria-label="visit.nombre[lang]" :style="{ 'border': '2px solid' + colors.color_resalte }">{{
              visit.nombre[lang] }}</div>
        </div>
      </div>
      <div class="title" :aria-label="title" v-html="title" v-if="filterSelected != null"></div>
      <div class="lista">
        <button class="btn-block" v-for="(elemento) in elementos" :key="elemento.id" :data-num="elemento.unidad_id"
          :style="{ 'border-bottom': '2px solid' + elemento.color_1 }" @click="seleccionado(elemento.unidad_id)"
          :aria-label="nombre(elemento)">
          <img :src="thumb(elemento)" class="thumb" :class="{ redondo: elemento.esRedondo }"/>
          <label class="titulo" v-html="nombre(elemento)"></label>
          <span class="sala">{{ elemento.ubicacion_nombre[lang] }}</span>
        </button>
      </div>
    </div>
    <div class="list-view-visit" v-if="isVisitSelected && !isFullList">
      <Topmenu :showList="true" :showList2="false"></Topmenu>
      <div class="title" :aria-label="titleVisit" v-html="titleVisit"></div>
      <div class="lista">
        <button class="btn-block" v-for="(elemento) in elementosVisit" :key="elemento.id" :data-num="elemento.unidad_id"
          :style="{ 'border-bottom': '2px solid' + elemento.color_1 }" @click="seleccionadoVisits(elemento.unidad_id)"
          :aria-label="nombre(elemento)">
          <img :src="thumb(elemento)" class="thumb" :class="{ redondo: elemento.esRedondo }" />
          <label class="titulo" v-html="nombre(elemento)"></label>
          <span class="sala">{{ elemento.ubicacion_nombre[lang] }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Topmenu from "@/components/TopMenu.vue";

import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "ItemView",
});
export default {
  name: "ListView",
  props: {
    showListView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Topmenu,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      dataX: this.$store.state.guiaData,
      search: "",
      lang: this.$store.state.locale,
      title: null,
    };
  },
  computed: {
    colors(){
      return this.$store.state.guia.colores;
    },
    colorResalte() {
      return this.$store.state.colorResalte;
    },
    path() {
      return this.$store.state.pathMedia + this.$store.state.idGuia + "/assets/"
    },
    elementos: function () {
      return this.$store.state.actualData;
    },
    elementosVisit: function () {
      return this.$store.state.visitUnits;
    },
    lenguaje: function () {
      return this.$store.state.locale;
    },
    isCustom() {
      if (this.$store.state.custom != null) {
        return true;
      } else {
        return false
      }
    },
    listVisits() {
      return this.$store.state.visits;
    },
    filterSelected() {
      return this.$store.state.filterId;
    },
    isVisitSelected() {
      console.log("isVisitSelected", this.$store.state.visitName != null);
      return this.$store.state.visitName != null;
    },
    titleVisit() {
      return this.$store.state.visitName;
    },
    isFullList() {
      return this.$store.state.isShowingList;
    },
  },
  updated() {
    /*if (this.$store.state.filterId != null) {
      console.log("filter", this.$store.state.filterId);
      const el = document.querySelector("#filter" + this.$store.state.filterId + "");
      el.classList.add("select");
      if (this.$store.state.filterId == "custom") {
        this.title = "Your tailored guide";
        this.$store.commit("setTone", this.$store.state.tonoCustom);
      } else {
        var visita = this.$store.state.visits.filter(x => x.visita_id == this.$store.state.filterId);
        console.log(visita[0].unidades);
        this.title = visita[0].nombre[this.lenguaje];
        this.$store.commit("setTone", visita[0].unidades[0].perfil_set[0].tono);
      }
    } else {
      this.$store.commit("setTone", 0);
    }*/
  },
  mounted() {
    this.title = "";
    this.$store.commit("saveFilterId", null);
  },
  methods: {
    closeFullList() {
      var id = this.$store.state.pointId;
      if (id != null) {
        if(this.isVisitSelected){
          this.seleccionadoVisits(id);
        }else{
          this.seleccionado(id);
        }
      } else {
        this.$store.commit("setIsShowingList", false);
        this.$store.commit("saveActualData", this.$store.state.guiaData);
        if (this.$store.state.pointData != null) {
          this.$store.commit("setShowListView", false);
          this.$store.commit("setShowItemView", true);
        } else {
          if (!this.isVisitSelected) {
            this.$store.commit("setShowListView", false);
          }
        }
      }
      this.$store.commit("saveFilterId", null);
    },
    filterList(id) {
      const el = document.querySelector("#filter" + id + "");
      const all = document.querySelectorAll(".filterOpt");

      for (var i = 0; i < all.length; i++) {
        all[i].classList.remove('select');
        all[i].style.backgroundColor = "#fff";

      }

      console.log(id);
      this.$store.commit("saveActualData", this.$store.state.guiaData);
      if (this.filterSelected == id) {
        this.$store.commit("saveActualData", this.$store.state.guiaData);
        this.$store.commit("saveFilterId", null);
        this.title = "";
        this.$store.commit("setTone", 0);
      } else {
        if (id == "custom") {
          this.title = "Your tailored guide";
          this.$store.commit("saveActualData", this.$store.state.custom);
          this.$store.commit("setTone", this.$store.state.tonoCustom);
        } else {
          var visita = this.$store.state.visits.filter(x => x.visita_id == id);
          console.log(visita);
          var visit = [];
          var v = [];
          visita[0].ordenunidades_set.forEach(el => {
            var v = this.elementos.filter(x => x.unidad_id == el.unidad);
            visit.push(v);
          });
          visit.forEach(el => {
            v.push(el[0]);
          });
          console.log("UNIDADES", v);
          this.title = visita[0].nombre[this.lenguaje];
          this.$store.commit("saveActualData", v);
          //this.$store.commit("setTone", visita[0].unidades[0].perfil_set[0].tono);
        }
        el.classList.add("select");
        el.style.backgroundColor=this.colors.color_resalte;
        this.$store.commit("saveFilterId", id);
        analytic.registerEvent("Filter", this.title);
      }
    },
    thumb(elemento) {
      return `${this.path}${elemento.thumb}`;
    },
    nombre(elemento) {
      var name = elemento.nombre[this.lenguaje];
      /*var words = name.split(" ");
      words = words.map((word) => {
        if (word == "and") return word;
        if (word.charAt(0) == "(")
          return "(" + word.charAt(1).toUpperCase() + word.slice(2);
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      var nombre = words.join(" ");*/
      return name;
    },
    seleccionado(numero) {
      console.log("buscando");
      //analytic.registerEvent("ListUnit", numero);
      this.$store.commit("setVisitName", null);
      for (var punto in this.$store.state.guiaData) {
        var localdata = this.$store.state.guiaData[punto];
        if (parseInt(numero) == localdata.unidad_id) {
          console.log(localdata.nombre[this.lang]);
          analytic.registerEvent("ListUnitName", localdata.nombre["es"]);
          // store data for other views
          //analytic.registerEvent("ListUnit", localdata.unidad_id);
          console.log(localdata);
          this.$store.commit("setPointData", localdata);
          this.$store.commit("setPointId", localdata.unidad_id);
          // this.camera = 'off';
        }
      }
    },
    seleccionadoVisits(numero) {
      console.log("buscando");
      //analytic.registerEvent("ListUnit", numero);
      for (var punto in this.$store.state.visitUnits) {
        var localdata = this.$store.state.visitUnits[punto];
        if (parseInt(numero) == localdata.unidad_id) {
          console.log(localdata.nombre[this.lang]);
          analytic.registerEvent("ListUnitName", localdata.nombre["es"]);
          // store data for other views
          //analytic.registerEvent("ListUnit", localdata.unidad_id);
          console.log(localdata);
          this.$store.commit("setPointData", localdata);
          this.$store.commit("setPointId", localdata.unidad_id);
          // this.camera = 'off';
        }
      }
    },
    loadSVG(id, icono, color) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          var elements = data.getElementsByTagName('path');
          for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i);
            if (element.hasAttribute("fill")) {
              element.setAttribute("fill", color)
            }
            if (element.hasAttribute("stroke")) {
              element.setAttribute("stroke", color)
            }
          }
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$small: 300px;
$medium: 700px;

.list-view {
  display: flex;
  flex-flow: column;
  flex: 1;
  //padding-top: 82px;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .list-view-complete,
  .list-view-visit {
    display: flex;
    flex-flow: column;
    flex: 1;
    //padding-top: 82px;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    .filter {
      width: 88%;
      display: flex;
      margin: 0 auto;
      margin-bottom: 1rem;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;

      .closeFullList {
        margin-right: 0.5rem;
      }

      .filterCont {
        width: 70%;
        display: flex;
        overflow-x: auto;
        margin-left: 0.5rem;

        .filterOpt {
          min-width: max-content;
          padding: 3px 5px;
          margin-right: 5px;
          border: 2px solid var(--ob-opt-border);
          color: var(--ob-opt-color);
          background-color: var(--ob-opt-bg);
          border-radius: 10px;
        }

        .select {
          color: var(--general-bg);
          background-color: v-bind('colorResalte');
          //background-color: var(--ob-opt-select-bg);
          //border: 2px solid var(--ob-opt-select-bg);
        }
      }
    }

    .hide {
      display: none;
    }

    .title {
      font-family: "bold";
      font-size: 16px;
      color: var(--general-titles-color);
      //margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .lista {
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 1rem;
      //height: 100%;

      .btn-block {
        display: block;
        border: none;
        background: none;
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 1.5rem 2rem;
        width: 85%;
        border-bottom: 2px solid rgba(87, 87, 87, 0.5);
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        position: relative;

        @media screen and (max-height: $medium) {
          margin: 1.1rem 2rem;
        }

        .sala {
          position: absolute;
          right: 0;
          bottom: 5px;
          color: var(--List-hall-color);
        }

        .thumb {
          //height: 78px;
          width: 78px;
          object-fit: contain;
          //border-radius: 45px;
          //filter: drop-shadow(0 4px 2px #000000bb);
          &.redondo{
            border-radius: 45px;
          }
        }

        .titulo {
          text-align: left;
          color: var(--general-titles-color);
          margin-left: 1rem;
          font-size: 15px;
          //display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "bold";
        }
      }

      :nth-last-child(1) {
        //border-bottom: none;
      }
    }
  }
}
</style>
