<template>
    <div class="gridGallery">

        <div class="grid">
            <div class="gridItem" v-for="(item, index) in galeria" :key="index" @click="showImage(index)">
                <img :src="path + item.medio.imagen" alt="">
            </div>
        </div>

        <button class="close round-btn" @click="closeModal" :aria-label="$t('cerrar')">
            <div class="tag" :id="'tagClose'" :ref="'tagClose'"
                :load="loadSVGRect('tagClose', 'btn_close.svg', color2)"></div>
        </button>

        <transition name="fade" mode="out-in">
            <Gallery :images="images" :index="index" :galeria="galeria" :point="point" v-if="galleryIsShown">
            </Gallery>
        </transition>
    </div>
</template>

<script>
//const $ = require('jquery');

import Gallery from "@/components/Gallery.vue";

import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Gallery",
});

export default {
    name: "gallery",
    props: ["images", "galeria", "point"],
    data() {
        return {
            currentIndex: 0,
            currenImage: null,
            zoomer: {
                minScale: 1,
                maxScale: 3,
                pivot: "image-center",
                zoomingElastic: false,
                limitTranslation: true,
            },
            publicPath: process.env.BASE_URL,

            isplaying: false,
            isTextHide: true,
            galleryIsShown: false,
        };
    },
    components: {
        Gallery,
    },
    mounted() {
        this.galeria.sort(function (a, b) { return a.posicion - b.posicion });
        this.currentIndex = this.index;
        this.currenImage = this.images[this.currentIndex];
        console.log("images", this.images);
    },
    computed: {
        path() {
            return this.$store.state.pathMedia + this.$store.state.idGuia + "/assets/"
        },
        etiquetaplay: function () {
            return this.isplaying ? this.$t("pause") : this.$t("play");
        },
        locale2: function () {
            return this.$store.state.locale;
        },
        pienovacio: function () {
            var pie = this.galeria[this.currentIndex].medio.nombre[this.locale2];
            console.log("pie", pie);
            if (pie === undefined) {
                pie = "";
            }
            return pie.trim().length != 0;
        },
        pie: function () {
            return `${this.galeria[this.currentIndex].medio.nombre[this.locale2]}`;
        },
        hasAudio: function () {
            return this.galeria[this.currentIndex].medio.audio[this.locale2] != null;
        },
        aud: function () {
            return `${this.path}${this.galeria[this.currentIndex].medio.audio[this.locale2]}`;
        },
        color1: function () {
            return this.point.color_1;
        },
        color2: function () {
            return this.point.color_2;
        },
        color3: function () {
            return this.point.color_3;
        },
        color4: function () {
            return this.point.color_4;
        },
        plainGallery() {
            return this.path+this.galeria[this.index].medio.imagen;
        },
    },
    methods: {
        showImage(index) {
            this.index = index;
            this.galleryIsShown = true;
            analytic.registerEvent("Gallery", "open");
        },
        closeModal() {
            this.$parent.closeGridGallery();
        },
        closeGallery() {
            this.galleryIsShown = false;
            analytic.registerEvent("Gallery", "close");
        },
        blockdownload(event) {
            console.log("taphold");
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        loadSVGRect(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('rect');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.gridGallery {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.90);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .grid {
        display: grid;
        grid-template-columns: auto auto auto;
        width: 100%;
        height: 75%;
        overflow: auto;
        justify-content: space-evenly;
        //position: absolute;
        //top: 15%;

        .gridItem {
            width: 30vw;
            height: 30vw;
            margin-bottom: 3vw;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .close {
        right: 1rem;
        left: unset;
        //filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.80));
        border-radius: 50%;
    }
}
</style>