<template>
    <div class="textosview">
        <div class="wrap">
            <button :aria-label="$t('aumentar')" class="btn amas" @click="fsize(1)">
                <div class="tag" :id="'tagMas'" :ref="'tagMas'" :load="loadSVG('tagMas', 'btn_mas.svg', color2)"></div>
            </button>
            <button :aria-label="$t('disminuir')" class="btn amenos" @click="fsize(-1)">
                <div class="tag" :id="'tagMenos'" :ref="'tagMenos'" :load="loadSVG('tagMenos', 'btn_menos.svg', color2)"></div>
            </button>
            <div class="wrapper">
                <div class="title" v-html="point.nombre[lang]"></div>
                <div class="text-container">
                    <div class="text" :class="classfontsize" v-html="txt"></div>
                </div>
            </div>
        </div>
        <button class="close round-btn" @click="closeModal" :aria-label="$t('cerrar')">
            <div class="tag" :id="'tagClose'" :ref="'tagClose'" :load="loadSVGRect('tagClose', 'btn_close.svg', color2)"></div>
        </button>
    </div>
</template>

<script>
import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "ItemView",
});

export default {
    name: "textosview",
    props: ["txt", "point"],
    data() {
        return {
            fontidx: 2,
            publicPath: process.env.BASE_URL,
        };
    },
    mounted() {

    },
    computed: {
        classfontsize() {
            return `p${this.fontidx}`;
        },
        lang: function () {
            return this.$store.state.locale;
        },
        color1: function () {
            return this.point.color_1;
        },
        color2: function () {
            return this.point.color_2;
        },
        color3: function () {
            return this.point.color_3;
        },
        color4: function () {
            return this.point.color_4;
        },
    },
    methods: {
        fsize(relsize) {
            if (relsize == -1) {
                analytic.registerEvent("TextFontSize", "decrease");
            } else {
                analytic.registerEvent("TextFontSize", "increase");
            }
            this.fontidx = this.fontidx + relsize;
            if (this.fontidx < 1) {
                this.fontidx = 1;
            }
            if (this.fontidx > 3) {
                this.fontidx = 3;
            }
            console.log(this.fontidx);
        },
        closeModal() {
            this.$parent.closeText();
        },
        blockdownload(event) {
            console.log("taphold");
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        loadSVG2(id, icono) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },

        loadSVG(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('path');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    var elements0 = data.getElementsByTagName('rect');
                    console.log(elements0);
                    for (var ii = 0; ii < elements0.length; ii++) {
                        var element0 = elements0.item(ii);
                        if (element0.hasAttribute("fill")) {
                            element0.setAttribute("fill", color)
                        }
                        if (element0.hasAttribute("stroke")) {
                            element0.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        loadSVGRect(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('rect');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.textosview {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.90);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrap {
        position: relative;
        width: 90%;
        height: 85%;
        margin: 7rem 0rem 4rem 0rem;
        background-color: white;

        .btn {
            background: none;
            border: none;
            outline: none;
            z-index: 1;

            &.amas {
                position: absolute;
                top: 4rem;
                right: 0.5rem;
            }

            &.amenos {
                position: absolute;
                top: 8rem;
                right: 0.5rem;
            }
        }

        .wrapper {
            position: relative;
            height: 100%;
            padding: 1.5rem 3.5rem 1rem 1rem;
            width: 100%;
            overflow: hidden;


            .title {
                margin: 1rem;
                font-size: 18px;
                font-family: 'bold';
                width: 100%;
            }

            .text-container {
                height: 85%;
                position: relative;
                overflow: scroll;
                -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), black 5%, black 95%, rgba(0, 0, 0, 0));
                padding-right: 15px;

                .text {
                    margin: 35px 0 25px 0;
                    font-size: 20px;
                    line-height: 34px;
                    text-align: start;
                    color: var(--text-color);
                    
                    :deep(p) {
                        transition: font-size 0.25s ease;
                    }

                    &.p1 :deep(p) {
                        line-height: 1.875rem !important;
                        font-size: 14px !important;

                        span {
                            //font-size: 0.8rem;
                            margin-top: 2rem;
                        }
                    }

                    &.p2 :deep(p) {
                        line-height: 2.125rem !important;
                        font-size: 16px !important;

                        span {
                            //font-size: 1rem;
                            margin-top: 2rem;
                        }
                    }

                    &.p3 :deep(p) {
                        line-height: 2.375rem !important;
                        font-size: 18px !important;

                        span {
                            //font-size: 16px;
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }

    .close {
        right: 1rem;
        left: unset;
        //filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.80));
        border-radius: 50%;

        .tag{
            svg{
                :deep rect{
                    fill: red;
                }
            }
        }
    }
}
</style>
