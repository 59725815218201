<template>
  <div class="gallery">
    <img class="imgBG" :src="images[index]" alt="" />

    <v-zoomer ref="zoomer" :minScale="zoomer.minScale" :maxScale="zoomer.maxScale" :pivot="zoomer.pivot"
      :zoomingElastic="zoomer.zoomingElastic" :limitTranslation="zoomer.limitTranslation">
      <img class="image-container" id="image-container" v-on:click.right="blockdownload" :src="images[index]" />
    </v-zoomer>

    <div v-if="pienovacio" class="pieCont" aria-hidden="true">
      <div class="pie0">
        <div v-if="pienovacio" class="pie" id="pie" aria-hidden="true" v-html="pie"></div>
      </div>
      <div id="toggleButton" @click="toggleClamping">
        {{ this.$t("showmore") }}
      </div>
    </div>

    <button v-if="currentIndex > 0" class="arrow prev" @click="loadPrevImage" :aria-label="$t('previmg')">
      <div class="tag" :id="'tagPrev'" :ref="'tagPrev'" :load="loadSVGCircle('tagPrev', 'btn_next-1.svg', color2)"></div>
    </button>

    <button v-if="currentIndex < images.length - 1" class="arrow next" @click="loadNextImage"
      :aria-label="$t('nextimg')">
      <div class="tag" :id="'tagNext'" :ref="'tagNext'" :load="loadSVGCircle('tagNext', 'btn_next.svg', color2)"></div>
    </button>

    <div class="aplayercontenedor" v-if="hasAudio">
      <figure>
        <audio id="audio" :src="aud" ref="myaudio" @ended="endedAudioG" @timeupdate="changeTimelinePosition"
          preload="auto"></audio>
        <ul id="video-controls" class="controls">
          <li class="audImg">
            <div class="tag" :id="'tagAud'" :ref="'tagAud'" :load="loadSVG('tagAud', 'b_ad.svg', color2)"></div>
          </li>
          <li>
            <button :aria-label="etiquetaplay" class="btn btn_play" :class="{ pause: isplaying }" @click="playAudioG"
              id="playpause" type="button"></button>
          </li>
          <li>
            <span id="current-time" class="time">0:00</span>
          </li>
          <li class="progress" id="progress">
            <div class="progress-bar" id="progressBar"></div>
          </li>
          <li>
            <span id="duration">0:00</span>
          </li>
        </ul>
      </figure>
    </div>

    <button class="close round-btn" @click="closeModal" :aria-label="$t('cerrar')">
      <div class="tag" :id="'tagClose'" :ref="'tagClose'" :load="loadSVGRect('tagClose', 'btn_close.svg', color2)"></div>
    </button>
    <div class="manitazoom" id="manitazoom">
      <div class="tag" :id="'tagMano'" :ref="'tagMano'" :load="loadSVG('tagMano', 'hand.svg', color2)"></div>
    </div>
  </div>
</template>

<script>
import VueZoomer from "vue-zoomer";
//const $ = require('jquery');

import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Gallery",
});

export default {
  name: "gallery",
  props: ["images", "index", "galeria", "point"],
  data() {
    return {
      currentIndex: 0,
      currenImage: null,
      zoomer: {
        minScale: 1,
        maxScale: 3,
        pivot: "image-center",
        zoomingElastic: false,
        limitTranslation: true,
      },
      publicPath: process.env.BASE_URL,

      isplaying: false,
      isTextHide: true,

      isClamping: true,
    };
  },
  components: {
    VZoomer: VueZoomer.Zoomer,
  },
  mounted() {
    this.galeria.sort(function (a, b) { return a.posicion - b.posicion });
    console.log("audio", this.aud);
    this.currentIndex = this.index;
    console.log("INDEX", this.currentIndex);
    this.currenImage = this.images[this.currentIndex];
    console.log("index", this.currenImage);
    var pie = this.galeria[this.currentIndex].medio.nombre[this.locale2];
    console.log("pie", pie);
    const el = document.getElementById("image-container");
    const mano = document.getElementById("manitazoom");
    el.addEventListener("touchmove", function () {
      mano.style.opacity = "0";
    }, false);

    //document.getElementById('toggleButton').innerText = this.isClamping ? 'Show more' : 'Show less';
    setTimeout(() => {
      //document.getElementById('pie').style.webkitLineClamp = this.isClamping ? '1' : 'unset';
      //document.getElementById('pie').style.maxHeight = this.isClamping ? '6.5em' : '';
      this.hideText();
    }, 100);
  },
  computed: {
    path() {
      return this.$store.state.pathMedia + this.$store.state.idGuia + "/assets/"
    },
    etiquetaplay: function () {
      return this.isplaying ? this.$t("pause") : this.$t("play");
    },
    locale2: function () {
      return this.$store.state.locale;
    },
    pienovacio: function () {
      var pie = this.galeria[this.currentIndex].medio.nombre[this.locale2];
      console.log("pie", pie);
      if (pie === undefined) {
        pie = "";
      }
      return pie.trim().length != 0;
    },
    pie: function () {
      return `${this.galeria[this.currentIndex].medio.nombre[this.locale2]}`;
    },
    hasAudio: function () {
      return this.galeria[this.currentIndex].medio.audio[this.locale2] != null;
    },
    aud: function () {
      return `${this.path}${this.galeria[this.currentIndex].medio.audio[this.locale2]}`;
    },
    color1: function () {
      return this.point.color_1;
    },
    color2: function () {
      return this.point.color_2;
    },
    color3: function () {
      return this.point.color_3;
    },
    color4: function () {
      return this.point.color_4;
    },
  },
  methods: {
    toggleClamping() {
      this.isClamping = !this.isClamping;
      document.getElementById('toggleButton').innerText = this.isClamping ? this.$t("showmore") : this.$t("showless");
      /*document.getElementById('pie').style.webkitLineClamp = this.isClamping ? '1' : 'unset';
      document.getElementById('pie').style.maxHeight = this.isClamping ? '6.5em' : '';*/
      var pie = document.getElementById("pie");
      if(this.isClamping){
        for (let index = 1; index < pie.getElementsByTagName('p').length; index++) {
          pie.getElementsByTagName('p')[index].classList.add("hiddenText");
        }
      }else{
        for (let index = 1; index < pie.getElementsByTagName('p').length; index++) {
          pie.getElementsByTagName('p')[index].classList.remove("hiddenText");
        }
      }
    },
    hideText(){
      document.getElementById('toggleButton').innerText = this.isClamping ? this.$t("showmore") : this.$t("showless");
      var pie = document.getElementById("pie");
      var count = pie.querySelectorAll("p").length;
      console.log("p", count);
      if(count > 1){
        document.getElementById("toggleButton").style.display = "block";
        pie.getElementsByTagName('p')[0].classList.add("first");
        for (let index = 1; index < pie.getElementsByTagName('p').length; index++) {
          pie.getElementsByTagName('p')[index].classList.add("hiddenText");
        }
      }else{
        document.getElementById("toggleButton").style.display = "none";
      }
    },
    loadPrevImage() {
      if (this.currentIndex - 1 < 0) {
        return;
      }
      this.currentIndex = parseInt(this.currentIndex) - 1;
      document.querySelector(".image-container").src =
        this.images[this.currentIndex];
      document.querySelector(".imgBG").src =
        this.images[this.currentIndex];
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      if(myAudio != undefined){
        myAudio.currentTime = 0;
        const progressBar = document.getElementById('progressBar');
        progressBar.style.width = '0%';
        const currentTime = document.getElementById('duration');
        currentTime.innerHTML = '0:00';
      }
      console.log("my audio", myAudio);
      this.isTextHide = true;
      analytic.registerEvent("PrevImage", this.currentIndex);

      if (this.pienovacio) {
        this.isClamping = true;
        setTimeout(() => {
          /*document.getElementById('toggleButton').innerText = this.isClamping ? this.$t("showmore") : this.$t("showless");
          document.getElementById('pie').style.webkitLineClamp = this.isClamping ? '1' : 'unset';
          document.getElementById('pie').style.maxHeight = this.isClamping ? '6.5em' : '';*/
          this.hideText();
        }, 100);
      }
    },
    loadNextImage() {
      if (this.currentIndex + 1 > this.images.length - 1) {
        return;
      }
      this.currentIndex = parseInt(this.currentIndex) + 1;
      document.querySelector(".image-container").src =
        this.images[this.currentIndex];
      document.querySelector(".imgBG").src =
        this.images[this.currentIndex];
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      if(myAudio != undefined){
        myAudio.currentTime = 0;
        const progressBar = document.getElementById('progressBar');
        progressBar.style.width = '0%';
        const currentTime = document.getElementById('duration');
        currentTime.innerHTML = '0:00';
      }
      this.isTextHide = true;

      analytic.registerEvent("NextImage", this.currentIndex);

      if (this.pienovacio) {
        this.isClamping = true;
        setTimeout(() => {
          /*document.getElementById('toggleButton').innerText = this.isClamping ? this.$t("showmore") : this.$t("showless");
          document.getElementById('pie').style.webkitLineClamp = this.isClamping ? '1' : 'unset';
          document.getElementById('pie').style.maxHeight = this.isClamping ? '6.5em' : '';*/
          this.hideText();
        }, 100);
      }
    },
    closeModal() {
      this.isplaying = false;
      this.isClamping = true;
      this.$parent.closeGallery();
    },
    blockdownload(event) {
      console.log("taphold");
      event.preventDefault();
      event.stopImmediatePropagation();
    },

    endedAudioG() {
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      myAudio.currentTime = 0;
      const progressBar = document.getElementById('progressBar');
      progressBar.style.width = '0%';
      const currentTime = document.getElementById('duration');
      currentTime.innerHTML = '0:00';
      analytic.registerEvent("AudioGallery", "ended");
    },
    changeTimelinePosition() {
      var myAudio = this.$refs.myaudio;
      const percentagePosition = (100 * myAudio.currentTime) / myAudio.duration;
      const progressBar = document.getElementById('progressBar');
      progressBar.style.width = percentagePosition + '%';
      const currentTime = document.getElementById('current-time');
      const minutes = Math.floor(myAudio.currentTime / 60);
      const seconds = Math.floor(myAudio.currentTime % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      currentTime.innerHTML = `${minutes}:${returnedSeconds}`;
    },
    playAudioG() {
      var myAudio = this.$refs.myaudio;
      console.log(myAudio.currentTime);
      if (myAudio.paused && !myAudio.ended) {
        myAudio.play();
        this.isplaying = true;
        const currentTime = document.getElementById('duration');
        const minutes = Math.floor(myAudio.duration / 60);
        const seconds = Math.floor(myAudio.duration % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        currentTime.innerHTML = `${minutes}:${returnedSeconds}`;
        analytic.registerEvent("AudioGallery", "play");
      } else {
        myAudio.pause();
        this.isplaying = false;
        analytic.registerEvent("AudioGallery", "pause");
      }
    },
    loadSVG2(id, icono) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    loadSVG(id, icono, color) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          var elements = data.getElementsByTagName('path');
          for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i);
            if (element.hasAttribute("fill")) {
              element.setAttribute("fill", color)
            }
            if (element.hasAttribute("stroke")) {
              element.setAttribute("stroke", color)
            }
          }
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    loadSVGRect(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('rect');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        loadSVGCircle(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('circle');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    var elements0 = data.getElementsByTagName('path');
                    console.log(elements0);
                    for (var i0 = 0; i0 < elements.length; i0++) {
                        var element0 = elements0.item(i0);
                        if (element0.hasAttribute("fill")) {
                            //element0.setAttribute("fill", color)
                        }
                        if (element0.hasAttribute("stroke")) {
                            element0.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.90);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .imgBG {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(10px) brightness(0.9);
    -webkit-filter: blur(10px) brightness(0.9);
  }

  .aplayercontenedor {
    --player-button-width: 3em;
    --sound-button-width: 2em;
    --space: 0.5em;
    margin: 0px auto;
    width: 90%;
    position: absolute;
    bottom: 16px;
    background-color: v-bind('color1');
    border-radius: 5px;
    padding: 0.2rem 0.5rem;

    .btn {
      background: none;
      border: none;
      outline: none;
    }

    .btn_play {
      height: 30px;
      width: 30px;
      margin: 0 0.5rem;
      --svg: url("./../../public/img/svg/b_play.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      -webkit-mask: var(--svg);
      mask: var(--svg);
      mask-repeat: no-repeat;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      background-color: v-bind('color2');

      &.pause {
        --svg: url("./../../public/img/svg/b_pause.svg");
      }
    }

    #video-controls {
      list-style: none;
      width: 100%;
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;

        span {
          color: v-bind('color2');
        }
      }

      .audImg {
        width: 50px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .progress {
        width: 63%;
        height: 5px;
        display: flex;
        align-items: center;
        justify-items: center;
        margin: 0 0.5rem;
        background-color: lightgrey;
        border-radius: 10px;

        .progress-bar {
          width: 0%;
          height: 100%;
          background-color: v-bind('color2');
          border-radius: 10px;
        }
      }
    }
  }

  .manitazoom {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 57%;
    right: 12%;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      animation: pulse 1.8s linear infinite;
    }

    #tagMano {
      animation: pulse 1.8s linear infinite;
    }
  }

  .pieCont {
    background-color: v-bind('color2');
    text-align: center;
    color: v-bind('color4');
    padding: 0.5rem;
    max-height: 75%;
    position: absolute;
    bottom: 68px;
    width: 90%;
    overflow: auto;
    display: flex;
    flex-flow: column;
    //align-items: flex-end;

    .pie {
      width: 100%;
      height: 100%;
      overflow: hidden;
      /*text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;*/
      //text-align: left;

      ::v-deep p {
        margin-bottom: 0;
        line-height: 1.2;
        font-size: 13px;
        //display: inline;
      }

      ::v-deep .hiddenText {
        //color: blue;
        display: none;
      }

      ::v-deep .showMore {
        text-align: right;
        text-decoration: underline;
      }
    }

    .showing {
      -webkit-line-clamp: unset;
      line-clamp: unset;
    }

    #toggleButton {
      text-align: right;
      margin-top: 0.5rem;
      align-items: self-end;
      text-decoration: underline;
      font-size: 13px;
    }
  }

  .vue-zoomer {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .arrow {
    position: absolute;
    border: unset;
    border-radius: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      width: 90%;
      height: auto;
    }

    &.prev {
      left: 1rem;
    }

    &.next {
      right: 1rem;
    }
  }

  .close {
    right: 1rem;
    left: unset;
    //filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.80));
    border-radius: 50%;
  }
}

@media (orientation: landscape) {
  .gallery {
    .imgBG {
      display: block;
    }

    .pieCont {
      display: none;
    }
  }
}
</style>
