"use strict";
var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var Page = function Page(id, name) {
  _classCallCheck(this, Page);

  this.id = id;
  this.obj = {
    name: name,
    count: 0,
    objetos: [],
  };
  this.startTime = new Date().getTime() / 1000;
  this.endTime = -1;
};

var Analytic = (function () {
  function Analytic(opts) {
    _classCallCheck(this, Analytic);

    this.options = {
      url: undefined,
    };
    if (opts) {
      this.options = opts;
    }
    this.pages = [];
    this.id = "";
    if (!localStorage.pages) {
      localStorage.pages = JSON.stringify(this.pages);
    } else {
      this.pages = JSON.parse(localStorage.pages);
    }
    if (!localStorage.id) {
      localStorage.id = this.create_UUID();
    }
    this.id = localStorage.id;
    this.readPage(this.options.idPag, this.options.namePag);
  }

  _createClass(Analytic, [
    {
      key: "findPage",
      value: function findPage(page) {
        var tmp = this.pages.find(function (element) {
          return element.id == page.id;
        });
        return tmp;
        /*if(!tmp){
                return tmp;
            }
            var read=new Page(tmp.id,tmp.obj.name);
            read.obj=tmp.obj;
            read.startTime=tmp.startTime;
            read.endTime=tmp.endTime;
            return read;*/
      },
    },
    {
      key: "setEndTime",
      value: function setEndTime() {
        var tmp = this.findPage(new Page(this.options.idPag, ""));
        tmp.endTime = new Date().getTime() / 1000;
        var newItem = {
          iduser: this.id,
          name: "Salida de página " + tmp.obj.name,
          count: tmp.obj.count,
          time: new Date().getTime(),
        };
        this.sendDataToServer(newItem);
        return this.saveUpdatePage(tmp);
      },
    },
    {
      key: "saveUpdatePage",
      value: function saveUpdatePage(page) {
        var tmp = this.findPage(page);
        if (!tmp) {
          this.pages.push(page);
        } else {
          tmp.obj.objetos = page.obj.objetos;
          tmp.obj.count = page.obj.count;
          tmp.startTime = page.startTime;
          tmp.endTime = page.endTime;
        }
        localStorage.pages = JSON.stringify(this.pages);
        return tmp;
      },
    },
    {
      key: "readPage",
      value: function readPage(id, name) {
        this.pages = JSON.parse(localStorage.pages);
        var obj = this.findPage(new Page(id, name));
        if (!obj) {
          this.saveUpdatePage(new Page(id, name));
        }
        return obj;
      },
    },
    {
      key: "readAll",
      value: function readAll() {
        return JSON.parse(localStorage.pages);
      },
    },
    {
      key: "readRaw",
      value: function readRaw() {
        return localStorage.pages;
      },
    },
    {
      key: "readData",
      value: function readData() {
        var data = {};
        data.pages = localStorage.pages;
        data.id = localStorage.id;
        return data;
      },
    },
    {
      key: "registerEvent",
      value: function registerEvent(event, value) {
        var tmp = this.findPage(new Page(this.options.idPag, ""));
        var newItem = {
          iduser: this.id,
          name: event,
          value: value,
          time: new Date().getTime(),
          origin: localStorage.currentRoute,
        };
        this.sendDataToServer(newItem);
        return this.saveUpdatePage(tmp);
      },
    },
    {
      key: "create_UUID",
      value: function create_UUID() {
        var dt = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          }
        );
        return uuid;
      },
    },
    {
      key: "create_winner_code",
      value: function create_winner_code() {
        if (!localStorage.winnerCode) {
          var id = "";
          var dt = new Date().getTime();
          id = dt.toString(16);
          localStorage.originalCode = id;
          localStorage.winnerCode = id.substring(3, 11);
        }
        return [localStorage.originalCode, localStorage.winnerCode];
      },
    },
    {
      key: "saveRating",
      value: function save_rating(suggestion, stars, q1, q2, q3, gohome) {
        var newItem = {
          iduser: this.id,
          suggestion: suggestion,
          stars: stars,
          q1: q1,
          q2: q2,
          q3: q3,
          origin: localStorage.currentRoute,
          time: new Date().getTime(),
        };
        var form_data = new FormData();
        for (var key in newItem) {
          form_data.append(key, newItem[key]);
        }
        fetch("./saveRatings.php", {
          method: "POST",
          body: form_data,
        })
          .then((response) => {
            if (!response.ok) throw Error(response.status);
            return response.json();
          })
          .then((response) => {
            gohome(response);
          })
          .catch(function (error) {
            console.warn(error);
          });
      },
    },
    {
      key: "sendDataToServer",
      value: function sendDataToServer(data) {
        var form_data = new FormData();
        for (var key in data) {
          form_data.append(key, data[key]);
        }
        if (this.options.url) {
          fetch(this.options.url, {
            method: "POST",
            body: form_data,
          })
            .then((response) => {
              if (!response.ok) throw Error(response.status);
              return response.json();
            })
            .catch(function (error) {
              console.warn(error);
            });
        }
      },
    },
    {
      key: "sendDataToServer2",
      value: function sendDataToServer(data) {
        if (this.options.url) {
          fetch(this.options.url, {
            method: "POST",
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          })
            .then((response) => {
              if (!response.ok) throw Error(response.status);
              return response.json();
            })
            .catch(function (error) {
              console.warn(error);
            });
        }
      },
    },
  ]);

  return Analytic;
})();

export { Analytic, Page };
