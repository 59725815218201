/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      localStorage.setItem("swStatus", "ready");
      window.dispatchEvent(
        new CustomEvent("swStatus-changed", {
          detail: {
            storage: localStorage.getItem("swStatus"),
          },
        })
      );
    },
    registered() {
      localStorage.setItem("swStatus", "registered");
      window.dispatchEvent(
        new CustomEvent("swStatus-changed", {
          detail: {
            storage: localStorage.getItem("swStatus"),
          },
        })
      );
    },
    updatefound() {
      localStorage.setItem("swStatus", "downloading");
      window.dispatchEvent(
        new CustomEvent("swStatus-changed", {
          detail: {
            storage: localStorage.getItem("swStatus"),
          },
        })
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
