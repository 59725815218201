<template>
  <div class="item-view">
    <Topmenu :showSearch="false" :showSettings="false" :showList2="false"></Topmenu>
    <Unitmenu></Unitmenu>
    <div v-if="showMainItem" class="main section">
      <div class="topWrap" :style="{ 'background-color': color2 }">
        <div class="titlecont">
          <div class="title" :style="{ 'color': color3 }" :aria-label="nombre" v-html="nombre"></div>
          <span class="sala" :style="{ 'color': color4 }" :aria-label="sala">{{ sala }}</span>
        </div>
        <!--<button tabindex="0" :aria-label="$t('volver')" class="btn close" @click="closeView">
          <img :src="btnback" />
        </button>-->
        <button tabindex="0" :aria-label="$t('prevunit')" class="btn close backU" @click="back">
          <div class="tag" :id="'tagBackU'" :ref="'tagBackU'" :load="loadSVG('tagBackU', 'btn_back_tema.svg', color3)">
          </div>
        </button>
        <button tabindex="0" :aria-label="$t('nextunit')" class="btn close nextU" @click="next">
          <div class="tag" :id="'tagNextU'" :ref="'tagNextU'" :load="loadSVG('tagNextU', 'btn_next_tema.svg', color3)">
          </div>
        </button>
      </div>

      <!-- Audio -->
      <div class="audcontenedor">
        <div class="gallery">
          <img :src="thumb" alt="Thumb" class="image" v-on:click.right="blockdownload" />
        </div>
        <audio id="audio" :src="`${lnkAudio}`" ref="myaudio" @ended="endedAudioG" preload="auto"></audio>
        <button :aria-label="etiquetaplay" class="btn btn_play" :class="{ pause: isplaying }" @click="playAudioG"
          id="playpause" type="button" v-if="hasAudio"></button>
        <button :aria-label="$t('less10s')" class="btn btn_secLess" id="secLess" type="button" v-if="isplaying"
          @click="backSeconds">
        </button>
        <button :aria-label="$t('more10s')" class="btn btn_secMore" id="secMore" type="button" v-if="isplaying"
          @click="nextSeconds"></button>
        <div class="txtCont">
          <div class="btntext btnOpt" @click="verTextos()" v-if="hasText" :aria-label="$t('readtext')"
            :style="{ 'background-color': color2, 'color': color4 }">
            <div class="tag" :id="'tagReadText'" :ref="'tagReadText'"
              :load="loadSVG('tagReadText', 'b_readtext.svg', color4)">
            </div>
            <span>{{ $t("readtext") }}</span>
          </div>
          <div class="btntextE btnOpt" @click="verTextosEasy()" v-if="hasTextEasy" :aria-label="$t('easyread')"
            :style="{ 'background-color': color2, 'color': color4 }">
            <div class="tag" :id="'tagReadEText'" :ref="'tagReadEText'"
              :load="loadSVG('tagReadEText', 'b_readtext.svg', color4)"></div>
            <span>{{ $t("easyread") }}</span>
          </div>
        </div>
        <div class="lseCont">
          <div class="btnlse btnOpt" :style="{ 'background-color': color2, 'color': color4 }" @click="verVideoLSM()"
            v-if="hasLSM" :aria-label="$t('signlanguage')">
            <div class="tag" :id="'tagLSM'" :ref="'tagLSM'" :load="loadSVG('tagLSM', 'b_asl.svg', color4)"></div>
            <span>{{ $t("signlanguage") }}</span>
          </div>
        </div>
      </div>

      <!-- Botones -->
      <div class="optionsCont">
        <div :aria-label="$t('gallery')" class="btn btn_content" @click="verImagen" v-if="hasImages">
          <div class="tag" :id="'tagGallery'" :ref="'tagGallery'" :load="loadSVG2('tagGallery', 'b_gallery.svg')"></div>
          <span>{{ $t("gallery") }}</span>
        </div>
        <div :aria-label="$t('videos')" class="btn btn_content" @click="verVideo" v-if="hasVideo">
          <div class="tag" :id="'tagVideo'" :ref="'tagVideo'" :load="loadSVG2('tagVideo', 'b_watch.svg')"></div>
          <span>{{ $t("videos") }}</span>
        </div>
        <div :aria-label="$t('links')" class="btn btn_content" @click="verEnlaces" v-if="hasEnl">
          <div class="tag" :id="'tagLinks'" :ref="'tagLinks'" :load="loadSVG2('tagLinks', 'b_link.svg')"></div>
          <span>{{ $t("links") }}</span>
        </div>
      </div>
    </div>
    <!-- Slides -->
    <transition name="fade" mode="out-in">
      <Gridgallery :images="plainGallery" :galeria="allImages" :point="point" v-if="galleryIsShown">
      </Gridgallery>
    </transition>

    <transition name="fade" mode="out-in">
      <Reproductor :video="lnkVideo" :sub="lnkVideoSubs" :lsm="lnkLSM" :point="point" v-if="reproductorIsShown">
      </Reproductor>
    </transition>

    <transition name="fade" mode="out-in">
      <Reproductorlse :video="lnkVideoLSM" :point="point" v-if="reproductorLSEIsShown"></Reproductorlse>
    </transition>

    <transition name="fade" mode="out-in">
      <Textview :txt="texto" :point="point" v-if="textIsShown"></Textview>
    </transition>

    <transition name="fade" mode="out-in">
      <Textview :txt="textoEasy" :point="point" v-if="textEasyIsShown"></Textview>
    </transition>

    <transition name="fade" mode="out-in">
      <Linksview :links="enlaces" :point="point" v-if="linksIsShown"></Linksview>
    </transition>
  </div>
</template>
<script>
import Topmenu from "@/components/TopMenu.vue";
import Unitmenu from "@/components/MenuUnit.vue";
import Gridgallery from "@/components/GridGallery.vue";
import Reproductor from "@/components/Reproductor.vue";
import Reproductorlse from "@/components/Reproductorlse.vue";
import Textview from "@/components/TextView.vue";
import Linksview from "@/components/Links.vue";
import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "ItemView",
});

export default {
  name: "ItemView",
  components: {
    Gridgallery,
    Reproductor,
    Reproductorlse,
    Textview,
    Linksview,
    Topmenu,
    Unitmenu,
  },
  props: {
    point: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      analytic.registerEvent("ItemView", "open");
    });
  },
  data() {
    return {
      showAudio: false,
      showTexto: false,
      showImagenes: false,
      showEnlaces: false,
      galleryIsShown: false,
      gallery: false,
      fontsize: 1.25,
      lineheight: 2.125,
      fontidx: 2,
      imgindex: 0,
      isplaying: false,
      publicPath: process.env.BASE_URL,
      isfirst: true,
      islast: false,
      scrolling: null,

      ifDownloadAudioD: false,
      ifDownloadPdf: false,
      ifDownloadImage: false,
      ifDownloadAudio: false,

      txtindex: 0,
      audindex: 0,

      currentBullet: 0,
      showLSE: false,
      isSubsActivated: false,
      isLSEsActivated: false,

      reproductorIsShown: false,
      reproductorLSEIsShown: false,
      textIsShown: false,
      textEasyIsShown: false,
      linksIsShown: false,
    };
  },
  computed: {
    path() {
      return this.$store.state.pathMedia + this.$store.state.idGuia + "/assets/"
    },
    showMainItem: function () {
      var mostrar =
        this.showAudio ||
        this.showEnlaces ||
        this.showTexto ||
        this.showLSE ||
        this.showImagenes;
      return !mostrar;
    },
    medios: function () {
      //var objIndexPerfil = this.point.perfil_set.findIndex(obj => obj.tono == this.$store.state.tone);
      var objIndexPerfil = 0;
      var objIndexMedios = this.point.perfil_set[objIndexPerfil].medios.findIndex(obj => obj.esPortada == true);
      return objIndexMedios;
    },
    perfil: function () {
      //var objIndexPerfil = this.point.perfil_set.findIndex(obj => obj.tono == this.$store.state.tone);
      var objIndexPerfil = 0;
      return objIndexPerfil;
    },
    color1: function () {
      return this.point.color_1;
    },
    color2: function () {
      return this.point.color_2;
    },
    color3: function () {
      return this.point.color_3;
    },
    color4: function () {
      return this.point.color_4;
    },
    hasImages: function () {
      console.log("IMG", this.point.perfil_set[this.perfil].imagengaleria_set.length);
      return this.point.perfil_set[this.perfil].imagengaleria_set.length > 0;
    },
    hasVideo: function () {
      console.log("VID", this.point.perfil_set[this.perfil].medios[this.medios].video);
      if(this.point.perfil_set[this.perfil].medios[this.medios].video != null){
        if(this.point.perfil_set[this.perfil].medios[this.medios].video.length > 0){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    hasLSM: function () {
      console.log("LSM", Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].signlanguage).length);
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].signlanguage).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.$store.state.locale].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    hasTextEasy: function () {
      console.log("EASY", Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].texto).length);
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].texto).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].texto[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].texto[this.$store.state.locale].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    hasText: function () {
      console.log("TEXT", Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].texto_largo).length);
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].texto_largo).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].texto_largo[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].texto_largo[this.$store.state.locale].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    hasEnl: function () {
      console.log("ENL", Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].enlaces).length);
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].enlaces).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].enlaces[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].enlaces[this.$store.state.locale].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    hasAudio: function () {
      console.log("AUD", Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].audio).length);
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].audio).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].audio[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].audio[this.$store.state.locale].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    etiquetaplay: function () {
      return this.isplaying ? this.$t("pause") : this.$t("play");
    },
    allImages: function () {
      return this.point.perfil_set[this.perfil].imagengaleria_set;
    },
    nombre: function () {
      return this.point.nombre[this.$store.state.locale];
    },
    sala: function () {
      return this.point.ubicacion_nombre[this.$store.state.locale];
    },
    texto: function () {
      return this.point.perfil_set[this.perfil].medios[this.medios].texto_largo[this.$store.state.locale];
    },
    textoEasy: function () {
      return this.point.perfil_set[this.perfil].medios[this.medios].texto[this.$store.state.locale];
    },
    enlaces: function () {
      return this.point.perfil_set[this.perfil].medios[this.medios].enlaces;
    },
    isEs: function () {
      return this.locale == "es";
    },
    thumb: function () {
      return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].imagen
        }`;
    },
    locale: function () {
      console.log(this.$store.state.locale);
      return this.$store.state.locale;
    },
    locale2: function () {
      console.log(this.$store.state.locale);
      return this.$store.state.locale;
    },
    lnkAudio: function () {
      console.log("audiodescripcion", this.point.perfil_set[this.perfil].medios[this.medios].audio[this.$store.state.locale]);
      return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].audio[this.$store.state.locale]}`;
    },
    lnkImagen: function () {
      return `${this.path}${this.point.perfil_set[this.perfil].imagengaleria_set[this.imgindex].medio.imagen
        }`;
    },
    lnkVideo: function () {
      return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].video}`;
    },
    lnkVideoLSM: function () {
      return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.$store.state.locale]}`;
    },
    lnkVideoSubs: function () {
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].subtitles).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].subtitles[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].subtitles[this.$store.state.locale].length > 0) {
            return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].subtitles[this.locale2]}`;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    lnkLSM: function () {
      if (Object.entries(this.point.perfil_set[this.perfil].medios[this.medios].signlanguage).length > 0) {
        if (this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.$store.state.locale] != undefined) {
          if (this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.$store.state.locale].length > 0) {
            return `${this.path}${this.point.perfil_set[this.perfil].medios[this.medios].signlanguage[this.locale2]}`;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    plainGallery() {
      return this.point.perfil_set[this.perfil].imagengaleria_set.map((item) => {
        console.log("gallery", item.medio.imagen);
        return `${this.path}${item.medio.imagen
          }`;
      });
    },
  },
  methods: {
    next() {
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      myAudio.currentTime = 0;
      if (this.$store.state.visitName == null) {
        var index = this.$store.state.guiaData.findIndex(this.checkId);
        if ((this.$store.state.guiaData.length - 1) == index) {
          this.point = this.$store.state.guiaData[0];
        } else {
          this.point = this.$store.state.guiaData[index + 1];
        }
      } else {
        var index0 = this.$store.state.visitUnits.findIndex(this.checkId);
        if ((this.$store.state.visitUnits.length - 1) == index0) {
          this.point = this.$store.state.visitUnits[0];
        } else {
          this.point = this.$store.state.visitUnits[index0 + 1];
        }
      }
      //analytic.registerEvent("NextUnit", this.point.unidad_id);
      analytic.registerEvent("NextUnitName", this.point.nombre["es"]);

    },
    back() {
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      myAudio.currentTime = 0;
      if (this.$store.state.visitName == null) {
        var index = this.$store.state.guiaData.findIndex(this.checkId);
        if (index == 0) {
          this.point = this.$store.state.guiaData[(this.$store.state.guiaData.length - 1)];
        } else {
          this.point = this.$store.state.guiaData[index - 1];
        }
      } else {
        var index0 = this.$store.state.visitUnits.findIndex(this.checkId);
        if (index0 == 0) {
          this.point = this.$store.state.visitUnits[(this.$store.state.visitUnits.length - 1)];
        } else {
          this.point = this.$store.state.visitUnits[index0 - 1];
        }
      }
      //analytic.registerEvent("PrevUnit", this.point.unidad_id);
      analytic.registerEvent("PrevUnitName", this.point.nombre["es"]);
    },
    checkId(x) {
      var id = this.point.unidad_id;
      return x.unidad_id == id;
    },
    closeView() {
      console.log("close");
      analytic.registerEvent("ItemView", "closed");
      this.$store.commit("setShowItemView", false);
      this.$store.commit("setPointData", null);
    },
    verImagen() {
      this.galleryIsShown = true;
      analytic.registerEvent("Grid Gallery", "open");
    },
    verEnlaces() {
      this.linksIsShown = true;
      analytic.registerEvent("Links", "open");
    },
    verVideo() {
      console.log("videolsm");
      this.reproductorIsShown = true;
      analytic.registerEvent("Video", "open");
    },
    verVideoLSM() {
      console.log("videolsm");
      this.reproductorLSEIsShown = true;
      analytic.registerEvent("LSM", "open");
    },
    verTextos() {
      this.textIsShown = true;
      analytic.registerEvent("Text", "open");
    },
    verTextosEasy() {
      this.textEasyIsShown = true;
      analytic.registerEvent("EasyText", "open");
    },
    closeEnlaces() {
      this.linksIsShown = false;
      analytic.registerEvent("Links", "close");
    },
    closeGridGallery() {
      this.galleryIsShown = false;
      analytic.registerEvent("Grid Gallery", "close");
    },
    closeText() {
      this.textIsShown = false;
      this.textEasyIsShown = false;
      analytic.registerEvent("Text", "close");
    },
    closeReproductor() {
      this.reproductorIsShown = false;
      analytic.registerEvent("Video", "close");
    },
    closeReproductorLSE() {
      this.reproductorLSEIsShown = false;
      analytic.registerEvent("LSM", "close");
    },
    playAudioG() {
      var myAudio = this.$refs.myaudio;
      console.log(myAudio.currentTime);
      if (myAudio.paused && !myAudio.ended) {
        myAudio.play();
        this.isplaying = true;
        analytic.registerEvent("Audio", "play");
        //analytic.registerEvent("audioPlay", this.point.unidad_id + "-" + this.audindex);
      } else {
        myAudio.pause();
        this.isplaying = false;
        analytic.registerEvent("Audio", "pause");
      }
    },
    endedAudioG() {
      this.isplaying = false;
      var myAudio = this.$refs.myaudio;
      myAudio.currentTime = 0;
      analytic.registerEvent("Audio", "ended");
      //analytic.registerEvent("audioEnded", this.point.unidad_id + "-" + this.audindex);
    },
    nextSeconds() {
      var myAudio = this.$refs.myaudio;
      var rest = myAudio.duration - myAudio.currentTime;
      if (rest > 10) {
        myAudio.currentTime = myAudio.currentTime + 10;
      } else {
        myAudio.currentTime = myAudio.duration - 10;
      }
    },
    backSeconds() {
      var myAudio = this.$refs.myaudio;
      if (myAudio.currentTime > 10) {
        myAudio.currentTime = myAudio.currentTime - 10;
      } else {
        myAudio.currentTime = 0;
      }
    },

    loadSVG(id, icono, color) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          var elements = data.getElementsByTagName('path');
          for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i);
            if (element.hasAttribute("fill")) {
              element.setAttribute("fill", color)
            }
            if (element.hasAttribute("stroke")) {
              element.setAttribute("stroke", color)
            }
          }
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    loadSVG2(id, icono) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    blockdownload(event) {
      console.log("taphold");
      event.preventDefault();
      event.stopImmediatePropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
$small: 300px;
$medium: 700px;

.item-view {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;

  .typeGuide {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    background-color: var(--unit-curated-bg);
    color: var(--unit-curated-color);
    font-size: 16px;
  }

  .section {
    //max-height: 100%;
    //height: -webkit-fill-available;
    width: 100%;
    //display: flex;
    justify-content: flex-start;
    flex-flow: column;
    overflow: scroll;
    position: absolute;
    height: 100%;
    background-color: var(--unit-menu-bg);

    .topWrap {
      display: flex;
      align-items: flex-start;
      background-color: var(--unit-title-bg);
      position: relative;
      //height: 80px;
      padding: 1rem;


      .btn {
        position: absolute;
        width: 30px;
        height: 30px;
        margin: auto;
        top: 0;
        bottom: 8%;
        left: 0.5rem;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
          object-fit: contain;
        }
      }

      .nextU {
        left: unset;
        right: 0.5rem;
      }

      .titlecont {
        width: 80%;
        margin: auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        height: max-content;
        color: var(--unit-title-color);

        .title {
          text-align: center;
          font-size: 16px;
        }

        .sala {
          margin-top: 0.5rem;
          font-size: 14px;
        }
      }
    }

    .sectionWrap {
      border-bottom: 1px solid #999999;
      display: flex;
      align-items: flex-start;
      padding: 1rem 0;
      flex-flow: row-reverse;
      justify-content: space-between;

      .btn {
        margin-right: 1rem;
        position: relative;
        top: 0;
        right: 0;
      }

      .title {
        margin-left: 1rem;
        margin-right: 0.5rem;
        text-overflow: ellipsis;
        text-align: left;
        color: #212529;
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        font-family: "bold";
      }
    }

    .title {
      font-size: 16px;
      margin-right: 5rem;
      margin-left: 0.5rem;
      /*display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;*/
      margin-top: 1rem;
      text-align: left;
      color: #ffffff;
    }

    &.main .title {
      margin-left: 1rem;
      margin-right: 0.5rem;
      text-overflow: ellipsis;
      //margin-top: 1rem;
      text-align: left;
      //color: #212529;
      //margin-bottom: 1rem;
      margin-top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-family: "bold";
    }

    .audcontenedor {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-flow: column;
      position: relative;
      width: 100%;
      height: 330px;

      .txtCont,
      .lseCont {
        position: absolute;
        width: -moz-max-content;
        width: max-content;
        font-size: 13px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        left: 3%;
        bottom: 3%;

        .btnOpt {
          margin-bottom: 0.3rem;
          display: flex;
          align-items: center;
          background-color: var(--unit-buttons-bg);
          color: var(--unit-buttons-color);
          border-radius: 5px;
          padding: 0 0.5rem;

          img {
            width: 50px;
            height: 20px;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .lseCont {
        left: unset;
        right: 3%;
      }

      .gallery {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }

      .btn {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }

      .btn_play {
        width: 70px;
        height: 70px;
      }

      .btn_secMore {
        left: 120px;
        top: 12px;
        width: 40px;
        height: 40px;
      }

      .btn_secLess {
        right: 120px;
        top: 12px;
        width: 40px;
        height: 40px;
      }
    }

    .optionsCont {
      font-size: 13px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      background-color: var(--unit-menu-bg);
      color: var(--unit-menu-color);
      width: 100%;
      min-height: 42%;
      padding-bottom: 1rem;

      .btn_content {
        display: flex;
        align-items: center;
        margin: 0% 4%;
        width: 92%;
        padding: 0.3rem;
      }
    }
  }

  .btnstextwrap {
    margin-bottom: 1rem;
    width: 90%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bullets {
      display: flex;
      align-items: center;
      justify-content: center;

      .bullettxt {
        margin: 0 0.5rem;
        width: 20px;
        height: 20px;
        //background-image: url("./../assets/onboarding/on_selec2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.active {
          //background-image: url("./../assets/onboarding/on_selec1.png");
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  .btnsOptAud {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .btnsAudwrap {
    margin-top: 1rem;
    width: 60%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bullets {
      display: flex;
      align-items: center;
      justify-content: center;

      .bullettxt {
        margin: 0 0.5rem;
        width: 20px;
        height: 20px;
        //background-image: url("./../assets/onboarding/on_selec2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.active {
          //background-image: url("./../assets/onboarding/on_selec1.png");
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  .wrapper {
    position: relative;
    height: 88%;
    margin: 1rem 0 2rem 0.75rem;
    width: 80%;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));

    /*&:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1111;
      background-image: linear-gradient(0deg, transparent, #fff);
      height: 50px;
    }

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1111;
      background-image: linear-gradient(transparent, #fff);
      height: 50px;
    }*/

    .text-container {
      height: 90%;
      position: relative;
      overflow: scroll;

      .text {
        margin: 25px 0 25px 0;
        font-size: 20px;
        line-height: 34px;
        text-align: start;
        color: #6C757D;
        padding-right: 15px;
        padding-top: 3.5%;
        padding-bottom: 1%;


        :deep(p) {
          transition: font-size 0.25s ease;
        }

        &.p1 :deep(p) {
          line-height: 1.875rem !important;
          font-size: 1rem !important;

          span {
            font-size: 0.8rem;
            margin-top: 2rem;
          }
        }

        &.p2 :deep(p) {
          line-height: 2.125rem !important;
          font-size: 1.25rem !important;

          span {
            font-size: 1rem;
            margin-top: 2rem;
          }
        }

        &.p3 :deep(p) {
          line-height: 2.375rem !important;
          font-size: 1.5rem !important;

          span {
            font-size: 16px;
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .btn {
    background: none;
    border: none;
    outline: none;

    &.close {
      margin-left: 1rem;
      //position: absolute;
      //top: 1rem;
      //left: 1rem;
    }

    &.btnlse,
    &.btntext,
    &.btntextE {
      padding: 0.3rem;
      background-color: #999999;
      border-radius: 5px;
    }

    &.cerrar {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &.amas {
      position: absolute;
      top: 9rem;
      right: 0.5rem;
    }

    &.amenos {
      position: absolute;
      top: 12rem;
      right: 0.5rem;
    }

    &.descarga {
      position: absolute;
      //bottom: 0;
      //left: 0;
      //right: 0;

      @media screen and (max-height: $medium) {
        margin-bottom: 0rem;
      }

      @media screen and (min-height: $medium) {
        //position: relative;
        margin-bottom: 0.5rem;
      }
    }

    &.btn_play {
      height: 50px;
      width: 50px;
      background-image: url("./../../public/img/svg/btn_play.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      &.pause {
        background-image: url("./../../public/img/svg/btn_pause.svg");
      }
    }

    &.btn_secMore {
      height: 50px;
      width: 50px;
      background-image: url("./../../public/img/svg/btn_next_10s.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &.btn_secLess {
      height: 50px;
      width: 50px;
      background-image: url("./../../public/img/svg/btn_back_10s.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &.btn_subs {
      height: 50px;
      width: 50px;
      background-color: #212529;

      &.subsOff {
        background-color: #6C757D;
      }
    }

    &.btn_lse {
      height: 50px;
      width: 50px;
      background-color: #212529;
      margin-left: 1rem;

      &.lseOff {
        background-color: #6C757D;
      }
    }

    &.prev,
    &.next,
    &.prev0,
    &.next0 {
      img {
        height: 42px;
      }

      &.hidden {
        img {
          display: none;
        }
      }
    }
  }
}

@keyframes pulso {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
</style>
