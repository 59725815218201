<template>
    <div class="reproductor">
        <div id="outer">
            <!--<video
            controls
            class="video"
            v-on:click.right="blockdownload"
            :src="`${lnkVideoLSM}`"
          ></video>-->
            <video id="video" class="video" refs="video" v-on:click.right="blockdownload" preload="metadata"
                @ended="endedVideo" @timeupdate="changeTimelinePositionVideo">
                <source :src="video" type="video/mp4" />
                <track label="Español" kind="subtitles" srclang="es" :src="sub" />
            </video>
            <video muted class="videolse" id="videolse" v-on:click.right="blockdownload" :src="lsm"
                :class="{ videoOff: isLSEsActivated }"></video>
            <!--<img class="girar" src="img/giro.png" alt="">-->
        </div>

        <div class="aplayercontenedorvid">
            <figure>
                <ul id="video-controls" class="controls">
                    <li>
                        <button :aria-label="etiquetaplay" class="btn btn_play" :class="{ pause: isplaying }"
                            @click="playVideo" id="playpause" type="button"></button>
                    </li>
                    <li>
                        <span id="current-time" class="time">0:00</span>
                    </li>
                    <li class="progress" id="progress">
                        <div class="progress-bar" id="progressBar"></div>
                    </li>
                    <li>
                        <span id="duration">0:00</span>
                    </li>
                    <li class="listMenu" @click="showMenu" v-if="hasMenu" :aria-label="$t('showopts')"></li>
                </ul>
            </figure>
        </div>

        <div class="menuOpt" v-if="isMenuShowing">
            <div :aria-label="$t('signlanguage')" class="btn btn_lse" :class="{ lseOff: isLSEsActivated }"
                @click="setLSE" id="setLSE" v-if="hasLSM">
                <div class="tag" :id="'tagLSM'" :ref="'tagLSM'" :load="loadSVG('tagLSM', 'b_asl.svg', color4)"></div>
                <span>{{ $t("signlanguage") }}</span>
            </div>
            <div :aria-label="$t('subtitles')" class="btn btn_subs" :class="{ subsOff: isSubsActivated }"
                @click="setSubs" id="setSubs" v-if="hasSub">
                <div class="tag" :id="'tagSub'" :ref="'tagSub'" :load="loadSVG('tagSub', 'b_subtitle.svg', color4)"></div>
                <span>{{ $t("subtitles") }}</span>
            </div>
        </div>

        <button class="close round-btn" @click="closeModal" :aria-label="$t('cerrar')">
            <div class="tag" :id="'tagClose'" :ref="'tagClose'" :load="loadSVGRect('tagClose', 'btn_close.svg', color2)"></div>
        </button>
    </div>
</template>

<script>
//const $ = require('jquery');
import { Analytic } from "./../../public/js/analytics/Analytic.js";

var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "ItemView",
});

export default {
    name: "reproductor",
    props: ["video", "sub", "lsm", "point"],
    data() {
        return {
            isplaying: false,
            publicPath: process.env.BASE_URL,
            isSubsActivated: false,
            isLSEsActivated: false,
            isMenuShowing: false,
            interval: null,
        };
    },
    mounted() {
        var myVideo = document.getElementById("video");
        this.isplaying = true;
        myVideo.play();
        this.interval = setInterval(() => {
            const currentTime = document.getElementById('duration');
            const minutes = Math.floor(myVideo.duration / 60);
            const seconds = Math.floor(myVideo.duration % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            currentTime.innerHTML = `${minutes}:${returnedSeconds}`;
        }, 1000);
    },
    computed: {
        etiquetaplay: function () {
            return this.isplaying ? this.$t("pause") : this.$t("play");
        },
        hasLSM: function () {
            console.log(this.lsm.length);
            return this.lsm.length > 0;
        },
        hasSub: function () {
            console.log("sub", this.sub.length);
            return this.sub.length > 0;
        },
        hasMenu: function () {
            return this.sub.length > 0 || this.lsm.length > 0;
        },
        color1: function () {
            return this.point.color_1;
        },
        color2: function () {
            return this.point.color_2;
        },
        color3: function () {
            return this.point.color_3;
        },
        color4: function () {
            return this.point.color_4;
        },
    },
    methods: {
        closeModal() {
            this.isSubsActivated = false;
            this.isLSEsActivated = false;
            this.isMenuShowing = false;
            clearInterval(this.interval);
            this.$parent.closeReproductor();
        },
        blockdownload(event) {
            console.log("taphold");
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        showMenu() {
            this.isMenuShowing = true;
        },
        endedVideo() {
            this.isplaying = false;
            var myVideo = document.getElementById("video");
            var videolse = document.getElementById("videolse");
            myVideo.currentTime = 0;
            videolse.currentTime = 0;
            const progressBar = document.getElementById('progressBar');
            progressBar.style.width = '0%';
            const currentTime = document.getElementById('duration');
            currentTime.innerHTML = '0:00';
            analytic.registerEvent("NormalVideo", "ended");
            //analytic.registerEvent("audioEnded", this.point.id + "-" + this.audindex);
        },
        changeTimelinePositionVideo() {
            var myAudio = document.getElementById("video");
            const percentagePosition = (100 * myAudio.currentTime) / myAudio.duration;
            const progressBar = document.getElementById('progressBar');
            progressBar.style.width = percentagePosition + '%';
            const currentTime = document.getElementById('current-time');
            const minutes = Math.floor(myAudio.currentTime / 60);
            const seconds = Math.floor(myAudio.currentTime % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            currentTime.innerHTML = `${minutes}:${returnedSeconds}`;
        },
        playVideo() {
            var myVideo = document.getElementById("video");
            var videolse = document.getElementById("videolse");
            this.isMenuShowing = false;
            if (myVideo.paused && !myVideo.ended) {
                myVideo.play();
                videolse.play();
                this.isplaying = true;
                analytic.registerEvent("NormalVideo", "play");
                //analytic.registerEvent("VideoPlay", this.point.id + "-" + this.audindex);
                const currentTime = document.getElementById('duration');
                const minutes = Math.floor(myVideo.duration / 60);
                const seconds = Math.floor(myVideo.duration % 60);
                const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
                currentTime.innerHTML = `${minutes}:${returnedSeconds}`;
            } else {
                myVideo.pause();
                videolse.pause();
                this.isplaying = false;
                analytic.registerEvent("NormalVideo", "pause");
            }
        },
        setSubs() {
            this.isMenuShowing = false;
            var myVideo = document.getElementById("video");
            var captionTrack = myVideo.textTracks[0];
            if (this.isSubsActivated) {
                this.isSubsActivated = false;
                captionTrack.mode = "hidden";
                analytic.registerEvent("VideoSubs", "close");
            } else {
                this.isSubsActivated = true;
                captionTrack.mode = "showing";
                analytic.registerEvent("VideoOptSubs", "open");
            }
        },
        setLSE() {
            this.isMenuShowing = false;
            var myVideo = document.getElementById("video");
            var videolse = document.getElementById("videolse");
            var position = myVideo.currentTime;
            if (this.isLSEsActivated) {
                this.isLSEsActivated = false;
                analytic.registerEvent("VideoLSM", "close");
            } else {
                this.isLSEsActivated = true;
                videolse.currentTime = position;
                analytic.registerEvent("VideoOptLSM", "open");
            }
        },
        loadSVG2(id, icono) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },

        loadSVG(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('path');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        loadSVGRect(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('rect');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.reproductor {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.90);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    #outer {
        //margin: 2rem auto;
        width: 100%;
        height: 77%;
        //border-radius: 20px;
        overflow: hidden;
        position: relative;

        /*@media screen and (max-height: $medium) {
        width: fit-content;
        height: 17rem;
      }*/

        .girar {
            width: 40%;
            height: 40%;
            -o-object-fit: contain;
            object-fit: contain;
            object-fit: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            pointer-events: none;
            opacity: 0;
            animation: pulso 1.5s;
            animation-iteration-count: 3;
            filter: invert(1);
        }

        .video {
            object-fit: contain;
            height: 100%;
            width: 100%;
            position: relative;
            left: 0;
            top: 0;
            opacity: 1;
            background-color: black;
        }

        .videolse {
            object-fit: contain;
            height: 37%;
            width: 38%;
            position: absolute;
            right: 0;
            bottom: 15%;
            opacity: 0;
            pointer-events: none;

            &.videoOff {
                opacity: 1;
            }
        }
    }

    .aplayercontenedorvid {
        --player-button-width: 3em;
        --sound-button-width: 2em;
        --space: 0.5em;
        margin: 0px auto;
        width: 90%;
        position: absolute;
        bottom: 1rem;
        background-color: v-bind('color1');
        border-radius: 5px;
        padding: 0.2rem 0.5rem;

        #video-controls {
            list-style: none;
            width: 100%;
            display: flex;
            align-items: center;

            li {
                display: flex;
                align-items: center;

                span {
                    color: v-bind('color2');
                }
            }

            .listMenu:after {
                content: '\2807';
                font-size: 20px;
                color: v-bind('color2');
                margin-left: 0.5rem;
            }

            .progress {
                width: 100%;
                height: 5px;
                display: flex;
                align-items: center;
                justify-items: center;
                margin: 0 0.5rem;
                background-color: lightgrey;
                border-radius: 10px;

                .progress-bar {
                    width: 0%;
                    height: 100%;
                    background-color: v-bind('color2');
                    border-radius: 10px;
                }
            }

            .btn {
                background: none;
                border: none;
                outline: none;
            }

            .btn_play {
                height: 30px;
                width: 30px;
                margin: 0 0.5rem;
                margin-left: 0;
                --svg: url("./../../public/img/svg/b_play.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                -webkit-mask: var(--svg);
                mask: var(--svg);
                mask-repeat: no-repeat;
                mask-size: contain;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center;
                -webkit-mask-size: contain;
                background-color: v-bind('color2');

                &.pause {
                    --svg: url("./../../public/img/svg/b_pause.svg");
                }
            }

            .btn_subs {
                height: 50px;
                width: 50px;
                background-color: #212529;

                &.subsOff {
                    background-color: #6C757D;
                }
            }

            .btn_lse {
                height: 50px;
                width: 50px;
                background-color: #212529;
                margin-left: 1rem;

                &.lseOff {
                    background-color: #6C757D;
                }
            }
        }
    }

    .menuOpt {
        position: absolute;
        width: max-content;
        padding: 0.5rem;
        background-color: v-bind(color2);
        font-size: 14px;
        color: v-bind('color4');
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        border-radius: 5px;
        right: 5%;
        bottom: 55px;

        .btn {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid;
            width: 100%;

            img {
                width: 50px;
                height: 30px;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .close {
        right: 1rem;
        left: unset;
        //filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.80));
        border-radius: 50%;
    }
}

@keyframes pulso {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@media (orientation: landscape) {
    .reproductor {
        #outer {
            .girar {
                display: none !important;
                opacity: 0 !important;
            }
        }
    }
}
</style>
