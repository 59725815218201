import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexStorage = new VuexPersist({
  key: "vuex",
  storage: window.sessionStorage,
  reducer: (state) => ({
    arOnboardingShown: state.arOnboardingShown,
    onboardingQuestions: state.onboardingQuestions,
    guiaData: state.guiaData,
    actualData: state.actualData,
    customData: state.customData,
    custom: state.custom,
    isSidebarNeeded: state.isSidebarNeeded,
    locale: state.locale,
    devlocale: state.devlocale,
    pointData: state.pointData,
    onboardingState: state.onboardingState,
    routeId: state.routeId,
    showItemView: state.showItemView,
    showList: state.showList,
    showOnBoarding: state.showOnBoarding,
    showSplash: state.showSplash,
    validLocales: state.validLocales,
    winnerCode: state.winnerCode,
    topbuttonconfig: state.topbuttonconfig,
    time: state.time,
    tone: state.tone,
    questions: state.questions,
    visits: state.visits,
    priority: state.priority,
    curated: state.curated,
    timeClass: state.timeClass,
    filterId: state.filterId,
    tonoCustom: state.tonoCustom,
    onboardingExist: state.onboardingExist,
    idGuia: state.idGuia,
    guia: state.guia,
    nameGuia: state.nameGuia,
    pathMedia: state.pathMedia,
    visitName: state.visitName,
    isShowingList: state.isShowingList,
    visitUnits: state.visitUnits,
    isListGeneral: state.isListGeneral,
    pointId: state.pointId,
    imgSplash: state.imgSplash,
    readyData: state.readyData,
    colorResalte: state.colorResalte,
  }),
});

export default new Vuex.Store({
  state: {
    arOnboardingShown: false, // was the ar onboarding shown?,
    onboardingQuestions: null,
    guiaData: null, // Guia data
    customData: false,
    actualData: null,
    custom: null,
    disabledLanguages: false, // helpfull to disable the button to change language in specific cases
    isSidebarNeeded: false,
    locale: null, // Lang of the app
    devlocale: null, // device's language
    onboardingState: null, // state of onboarding is null or shown
    pointData: null, //
    routeId: null, // current route id
    showItemView: false, //
    showOnBoarding: false,
    showSplash: true, // the need to show the splash
    showList: false,
    validLocales: ["es", "en"], // valid langs
    winnerCode: null, // the code to get the prize
    topbuttonconfig: [true, false, false],
    time: null,
    tone: null,
    questions: null,
    visits: null,
    priority: null,
    curated: null,
    timeClass: null,
    filterId: null,
    tonoCustom: null,
    onboardingExist: true,
    idGuia: null,
    guia: null,
    nameGuia: null,
    pathMedia: null,
    visitName: null,
    isShowingList: false,
    visitUnits: null,
    isListGeneral: false,
    pointId: null,
    imgSplash: null,
    readyData: false,
    colorResalte: null,
  },
  mutations: {
    setOnboardingState(state, payload) {
      state.onboardingState = payload;
    },
    saveGuiaData(state, payload) {
      state.guiaData = payload;
    },
    saveOnboardingQuestions(state, payload) {
      state.onboardingQuestions = payload;
    },
    saveCustomData(state, payload) {
      state.customData = payload;
    },
    saveActualData(state, payload) {
      state.actualData = payload;
    },
    saveCustom(state, payload) {
      state.custom = payload;
    },
    setPointData(state, payload) {
      state.pointData = payload;
    },
    settopbuttonconfig(state, payload) {
      state.topbuttonconfig = payload;
    },
    setShowItemView(state, payload) {
      state.showItemView = payload;
    },
    setShowListView(state, payload) {
      state.showList = payload;
    },
    setShowOnBoarding(state, payload) {
      state.showOnBoarding = payload;
    },
    updateLocale(state, payload) {
      state.locale = payload;
    },
    updateDevLocale(state, payload) {
      state.devlocale = payload;
    },
    setRouteId(state, payload) {
      state.routeId = payload;
    },
    setShowSplash(state, payload) {
      state.showSplash = payload;
    },
    toggleDisabledLanguages(state, payload) {
      state.disabledLanguages = payload;
    },
    setAROnboardingAsShown(state) {
      state.arOnboardingShown = true;
    },
    setSidebarNeeded(state, payload) {
      state.isSidebarNeeded = payload;
    },
    winnerCode(state, payload) {
      state.winnerCode = payload;
    },
    setTime(state, payload) {
      state.time = payload;
    },
    setTone(state, payload) {
      state.tone = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    saveVisits(state, payload) {
      state.visits = payload;
    },
    savePriority(state, payload) {
      state.priority = payload;
    },
    saveCurated(state, payload) {
      state.curated = payload;
    },
    saveTimeClass(state, payload) {
      state.timeClass = payload;
    },
    saveFilterId(state, payload) {
      state.filterId = payload;
    },
    setTonoCustom(state, payload){
      state.tonoCustom = payload;
    },
    onboardingExist(state, payload){
      state.onboardingExist = payload;
    },
    setIdGuia(state, payload){
      state.idGuia = payload;
    },
    setGuia(state, payload){
      state.guia = payload;
    },
    setNameGuia(state, payload){
      state.nameGuia = payload;
    },
    setPathMedia(state, payload){
      state.pathMedia = payload;
    },
    setVisitName(state, payload){
      state.visitName = payload;
    },
    setIsShowingList(state, payload){
      state.isShowingList = payload;
    },
    setVisitUnits(state, payload){
      state.visitUnits = payload;
    },
    setIsListGeneral(state, payload){
      state.isListGeneral = payload;
    },
    setPointId(state, payload){
      state.pointId = payload;
    },
    setImgSplash(state, payload){
      state.imgSplash = payload;
    },
    isReadyData(state, payload){
      state.readyData = payload;
    },
    setColorResalte(state, payload){
      state.colorResalte = payload;
    },
  },
  getters: {},
  actions: {},
  modules: {},
  plugins: [vuexStorage.plugin],
});
