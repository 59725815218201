<template>
    <div class="linksview">
        <div class="wrap">
            <div class="wrapper">
                <div class="enl-wrap">
                    <div class="text" v-html="links[$store.state.locale]"></div>
                </div>
            </div>
        </div>
        <button class="close round-btn" @click="closeModal" :aria-label="$t('cerrar')">
            <div class="tag" :id="'tagCloseE'" :ref="'tagCloseE'" :load="loadSVGRect('tagCloseE', 'btn_close.svg', color2)"></div>
        </button>
    </div>
</template>

<script>
export default {
    name: "linksview",
    props: ["links", "point"],
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    mounted() {

    },
    computed: {
        color1: function () {
      return this.point.color_1;
    },
    color2: function () {
      return this.point.color_2;
    },
    color3: function () {
      return this.point.color_3;
    },
    color4: function () {
      return this.point.color_4;
    },

    },
    methods: {
        closeModal() {
            this.$parent.closeEnlaces();
        },
        blockdownload(event) {
            console.log("taphold");
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        loadSVG2(id, icono) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        loadSVG(id, icono, color) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          var elements = data.getElementsByTagName('path');
          for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i);
            if (element.hasAttribute("fill")) {
              element.setAttribute("fill", color)
            }
            if (element.hasAttribute("stroke")) {
              element.setAttribute("stroke", color)
            }
          }
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    loadSVGRect(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('rect');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.linksview {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.90);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrap {
        position: relative;
        width: 90%;
        height: 85%;
        margin: 7rem 0rem 4rem 0rem;
        background-color: white;

        .wrapper {
            position: relative;
            height: 100%;
            padding: 1rem;
            width: 100%;
            overflow: hidden;

            .enl-wrap {
                display: flex;
                width: 100%;
                justify-content: space-between;
                border-bottom: 1px solid rgba(87, 87, 87, 0.5);
                margin-bottom: 1rem;
                padding-bottom: 1rem;

                .icon {
                    width: 25%;

                    img {
                        width: 85%;
                        height: 100%;
                        object-fit: contain;
                        object-position: top left;
                    }
                }

                .text {
                    width: 70%;
                    text-align: left;
                    color: var(--text-color);
                    font-size: 16px;
                }
            }

            :nth-last-child(1) {
                border-bottom: none;
            }
        }
    }

    .close {
        right: 1rem;
        left: unset;
        //filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.80));
        border-radius: 50%;
    }
}
</style>
