<template>
  <transition name="fade">
    <div v-if="showSplash" name="fade" class="splash">
      <img class="img1Splash" :alt="$t('splash_alt')" src="img/leyes_logo.jpg" />
    </div>
  </transition>
</template>
<script>
//const $ = require('jquery');
export default {
  name: "splash",
  data() {
    return {
      phase: 0,
      loading: true,
    };
  },
  mounted() {
    if(this.$store.state.showSplash){
      this.$store.commit("setShowOnBoarding", false);
      this.$store.commit("setOnboardingState", "shown");
      //if(this.readyData){
        /*setTimeout(() => {
          this.$store.commit("setShowOnBoarding", true);
          this.$store.commit("setOnboardingState", null);
          this.loading = false;
          this.$store.commit("setShowSplash", false);
          //window.location.reload();
        }, 3000);*/
        var interval = setInterval(() => {
          if(this.readyData){
            this.$store.commit("setShowOnBoarding", true);
            this.$store.commit("setOnboardingState", null);
            this.loading = false;
            this.$store.commit("setShowSplash", false);
            console.log("ready");
            clearInterval(interval);
          }
        }, 500);
      //}
    }else{
      if(this.$store.state.onboardingState == null){
        this.$store.commit("setShowOnBoarding", true);
        this.$store.commit("setOnboardingState", null);
      }
    }
  },
  methods:{
  },
  computed: {
    readyData(){
      return this.$store.state.readyData;
    },
    showSplash() {
      return this.$store.state.showSplash;
    },
    getImage(){
      return this.$store.state.guia.configuracion.logo1;
    },
    image(){
      return this.$store.state.pathMedia+ this.$store.state.idGuia + "/assets/"+this.$store.state.imgSplash;
    },
    path(){
      return this.$store.state.pathMedia;
    }
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter {
  opacity: 0;
}
.splash {
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  // background-color: #537b33;
}

// video version
.splash {
  background-color: var(--general-bg);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .img1Splash{
    width: 70%;
    height: 70%;
  }
}
</style>
