<template>
  <div id="app" :class="{ disabledPointerEvents: !pointerEvents }">
    <transition name="fade" mode="out-in">
      <list-view :showListView="showListView && !showItemView" />
    </transition>
    <transition name="fade" mode="out-in">
      <home :showHome="showHome && !showItemView && isReadyData"/>
    </transition>
    <transition name="fade" mode="out-in">
      <item-view v-if="showItemView" :point="pointData" />
    </transition>
    <!--<on-boarding v-if="showOnBoarding" />-->
    <splash />
  </div>
</template>

<script>
import Home from "@/components/Home.vue"
//import TopMenu from "@/components/TopMenu.vue";
//import OnBoarding from "@/components/OnBoarding";
import ItemView from "@/components/ItemView";
import ListView from "@/components/ListView";
import Splash from "@/components/Splash.vue";
import { Analytic } from "./../public/js/analytics/Analytic.js";
import platform from "platform";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Main",
});

export default {
  name: "App",
  data() {
    return {
      pointerEvents: true, // enable or disable interaction on app
      scannedPoint: null, // punto escaneado
      showTopOptions: true, // show top options
      showMapButton: true, // show map button on top options
      showScannerButton: true, // show scanner button on top options
      showOnBoarding: false, // show onboarding
      showItemView: false,
      showScannerView: false,
      showListView: false,
      showHome: false,
      showBottom: true,
      pointData: null,
      infoSection: null, // selected section to show in info
      showInfo: false, // show info section
      showRewards: false, // show rewards section
      swStatus: null, // status of the download service worker
    };
  },
  created() {
    // using device language or defaults to "es"
    if (this.$store.state.locale == null) {
      var validlocales = ["es", "en"];
      var ifacelocales = ["es", "en"];
      let deviceLanguage = this.getDeviceLanguage();
      console.log(deviceLanguage);
      if (validlocales.includes(deviceLanguage)) {
        if (ifacelocales.includes(deviceLanguage)) {
          this.$store.commit("updateLocale", deviceLanguage);
        } else {
          this.$store.commit("updateLocale", "en");
        }
        if (deviceLanguage)
          this.$store.commit("updateDevLocale", deviceLanguage);
      } else {
        this.$store.commit("updateLocale", "en");
        if (deviceLanguage) this.$store.commit("updateDevLocale", "en");
      }

      analytic.registerEvent("URL", window.location.href);
      setTimeout(() => {
        try {
          var obj = {
            browser: platform.name,
            browserVersion: platform.version,
            OSFamily: platform.os.family,
            OSVersion: platform.os.version,
            model: platform.product,
          };
          var data = JSON.stringify(obj);
          analytic.registerEvent("BrowserInfo", data);
        } catch (e) {
          console.log("error: ", e);
        }
      }, 100);
      setTimeout(() => {
        analytic.registerEvent("Lang", deviceLanguage);
      }, 150);
      setTimeout(() => {
        if (document.referrer != "") {
          analytic.registerEvent("Referrer", document.referrer);
        }
      }, 200);
    } else {
      analytic.registerEvent("Lang", this.$store.state.locale);
    }

    document.addEventListener("visibilitychange", this.visibilityChange, false);
    navigator.serviceWorker.ready.then(this.swReady);

    // register listener for toggle pointer events
    this.$root.$on("togglePointerEvents", this.togglePointerEvents);
  },
  mounted() {
    window.addEventListener("swStatus-changed", (event) => {
      this.swStatus = event.detail.storage;
      console.log("[sw chgd] ", this.swStatus);
    });
    navigator.serviceWorker.addEventListener("message", function (event) {
      console.log("[sw msg] ", event);
    });
    if (this.$store.state.onboardingExist) {
      if (this.$store.state.onboardingState !== null) {
        console.log("dont show onboarding");
        this.showOnBoarding = false;
      } else {
        console.log("do show onboarding");
        this.$store.commit("setShowOnBoarding", true);
        this.showOnBoarding = true;
      }
    } else {
      console.log("dont show onboarding");
      this.showOnBoarding = false;
    }

    var lastconfig = this.$store.state.topbuttonconfig;
    this.showHome = true;
    console.log("true home");
    this.showBottom = lastconfig[0];

    if (this.$store.state.showList) {
      this.$store.commit("setShowListView", true);
      this.showListView = true;
      this.showHome = false;
      console.log("showListView");
    }

    //console.log("COLORES", this.colorPrincipal);
  },
  components: {
    ItemView,
    ListView,
    //OnBoarding,
    Splash,
    //TopMenu,
    Home,
  },
  watch: {
    "$store.state.pointData": function () {
      if (this.$store.state.pointData != null) {
        console.log("cambio");
        this.showTopOptions = false;
        this.showItemView = true;
        this.pointData = this.$store.state.pointData;
        this.$store.commit("setShowItemView", true);
      } else {
        this.showTopOptions = true;
        this.showItemView = false;
      }
    },
    "$store.state.showList": function () {
      if (this.$store.state.showList) {
        this.$store.commit("setShowListView", true);
        this.showListView = true;
        this.showHome = false;
        console.log("showListView");
      } else {
        this.$store.commit("setShowListView", false);
        this.showListView = false;
        this.showHome = true;
        console.log("NOshowListView");
      }
    },
    "$store.state.showItemView": function () {
      console.log("showItemView");
    },
    "$store.state.showOnBoarding": function () {
      this.showOnBoarding = this.$store.state.showOnBoarding;
    },
    "$store.state.readyData": function(){
      this.showHome = true;
    }
  },
  methods: {
    // Toggle the pointers events on entire app based on status
    //  params:
    //     - status: Bool
    togglePointerEvents(status) {
      this.pointerEvents = status;
    },
    // Register visibility change on analytics
    //  params:
    //     - e: visibilty change param (?)
    visibilityChange(e) {
      e.value;
      analytic.registerEvent("Hide/minimized", document.hidden);
    },
    // Obtain broswer/device language, if the found locale ain't valid or is null, default to portuguese
    getDeviceLanguage() {
      var locale = navigator.language || navigator.userLanguage;
      console.log(locale);
      locale = locale.toLocaleLowerCase().substring(0, 2);
      return locale;
    },
    getLastPath(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    toggleOnboarding() {
      analytic.registerEvent("AyudaOnBoarding", "open");
      this.$store.commit("setShowOnBoarding", true);
    },
  },
  computed: {
    colorPrincipal(){
      return this.$store.state.guia;
    },
    isReadyData(){
      return this.$store.state.readyData;
    }
  }
};
</script>

<style lang="scss">
#app {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;

  &.disabledPointerEvents {
    pointer-events: none;
  }

  .btn-ayuda {
    position: absolute;
    z-index: 2;
    right: 1rem;
    bottom: 1rem;
    height: 40px;
    width: 40px;
  }
}

/**Animatons */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
