import Vue from "vue";
import App from "./App.vue";
import store from "./store";

Vue.config.productionTip = false;

// Localization lib
import i18n from "./i18n";
Vue.use(i18n);

// Service worker
import "./registerServiceWorker";

// Custom global styles
import "@/customStyles/styles.scss";
import "@/customStyles/stylesVar.scss";

Number.prototype.toFixedNumber = function (digits, base) {
  var pow = Math.pow(base || 10, digits);
  return Math.round(this * pow) / pow;
};

new Vue({
  store,
  i18n,
  async mounted() {
    this.$i18n.locale = this.$store.state.locale;

    this.$store.commit("setPathMedia", "https://leyesdereforma-veracruz.mx/media/guias/");

    const conf = "https://arvores.org/guia/Reforma/config";
    try {
      const response = await fetch(conf, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      this.$store.commit("setImgSplash", json.logo1);
    } catch (error) {
      console.error(error.message);
    }

    const guia = "https://arvores.org/media/guias/4/guia.json";
    try {
      const response = await fetch(guia, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      var data = json;
      data.visitas = json.visitas.filter(x => x.publicada == true);
      data.visitas.forEach(item => {
        item.ordenunidades_set.sort(function (a, b) { return a.posicion - b.posicion });
      });
      this.guiaData = data;
      this.$store.commit("setIdGuia", this.guiaData.guia_id);
      this.$store.commit("setGuia", this.guiaData);
      this.$store.commit("setNameGuia", this.guiaData.nombre[this.$store.state.locale]);
      this.$store.commit("saveVisits", this.guiaData.visitas);
      console.log("onboarding", this.guiaData.onboarding);
      this.$store.commit("setColorResalte", this.guiaData.colores.color_resalte);
      if (this.guiaData.onboarding === undefined) {
        this.$store.commit("onboardingExist", false);
      } else {
        if (this.guiaData.onboarding.length == 0) {
          this.$store.commit("onboardingExist", false);
        } else {
          this.$store.commit("saveOnboardingQuestions", this.guiaData.onboarding);
        }
      }

      console.log("READY");
    } catch (error) {
      console.error(error.message);
    }

    const unit = "https://arvores.org/media/guias/4/unidades.json";
    try {
      const response = await fetch(unit, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      var data0 = json;
      data0 = json.filter(x => x.publicada == true);
      this.unidades = data0;
      this.unidades.sort(function (a, b) { return a.posicion - b.posicion });
      this.$store.commit("saveGuiaData", this.unidades);
      console.log("READY UNITS");
      this.$store.commit("isReadyData", true);
    } catch (error) {
      console.error(error.message);
    }
  },
  data() {
    return {
      guiaData: null,
      unidades: null,
    };
  },
  render: (h) => h(App),
}).$mount("#app");
